import { Badge, IconButton, Tooltip } from '@mui/material'
import { useGetActionsQuery } from 'apis/patient.api'
import { todoIcon } from 'assets/svgIcons'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { appWhite } from 'theme/colors'

const iconButtonStyle = {
  border: `1px solid #DDDFE4`,
  color: '#9E9E9E',
  borderRadius: '50%',
}

const ActionItemIcon = () => {
  const navigate = useNavigate();
  const { data } = useGetActionsQuery();
  const { count = 0 } = data || {};

  const handleClick = () => {
    navigate('/action-items')
  }

  return (
    <Tooltip title="Action Items">
      <Badge
        badgeContent={count}
        // badgeContent={unreadNotifications?.length + (count ? unreadCount : 0)} 
        sx={{
          '& .MuiBadge-badge ': {
            backgroundColor: '#E3757A',
            top: '8px',
            right: '4px',
            color: appWhite
          }
        }}>
        <IconButton sx={iconButtonStyle} onClick={handleClick}>
          {todoIcon}
        </IconButton>
      </Badge>
    </Tooltip>
  )
}

export default ActionItemIcon