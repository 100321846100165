import { Avatar, CircularProgress, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, Stack, Typography } from '@mui/material'
import SettingsLogo from 'assets/logos/SettingsLogo';
import SupportLogo from 'assets/logos/SupportLogo';
import { useState } from 'react'
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { localStorageService } from 'shared/services/localStorageService';
import { useLogoutMutation } from 'apis/auth.api';
import { resetUser, setSelfLogout } from 'slices/user.slice';
import { useDispatch } from 'react-redux';
import useSelf from 'shared/hooks/useSelf';
import VerificationChip from 'components/VerificationChip';
import useDeleteModalState from 'shared/hooks/useDeleteModalState';
import Feedback from 'components/Feedback';


const WrapperStyle = {
  top: "20px",
  '& .MuiPaper-root': {
    minWidth: "230px",
    borderRadius: '8px',
    boxShadow: "none",
    border: "1px solid #DDDFE4",
    '& .MuiList-root': {
      padding: '12px!important',
      '& .MuiListItem-root:not(:last-child)': {
        marginBottom: 1,
      },
      '& .MuiDivider-root': {
        marginBottom: 1,
      }
    }
  }
}

const buttonStyle = {
  padding: '6px 3px 6px 2px',
  gap: "8px",
  borderRadius: "8px",
  ":hover": {
    "& .MuiListItemIcon-root": {
      display: "none",
    },
    padding: "6px 12px",
  }
}

const iconWidth = { minWidth: '20px' }
const ProfileDropdown = () => {
  const { first_name, last_name,
    avatar_url
  } = useSelf();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [logout, { isLoading: isLogoutLoading }] = useLogoutMutation();
  const { openConfirm, openConfirmModal, closeConfirm } = useDeleteModalState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectToSettings = () => {
    handleClose();
    navigate("/settings");
  }

  const onLogoutSuccess = () => {
    dispatch(resetUser());
    dispatch(setSelfLogout(true));
    localStorageService.logout();
  }

  const handleLogout = () => {
    if (localStorageService?.getToken()) {
      logout({ refresh: localStorageService?.getRefreshToken() }).unwrap().then(() => {
        onLogoutSuccess();
      }).catch(e => {
        console.log(e)
      })
    } else {
      onLogoutSuccess()
    }
  }

  const openFeedbackModal = () => {
    handleClose();
    openConfirmModal();
  }

  return (
    <>
      <Stack sx={{ cursor: 'pointer' }} direction="row" alignItems="center" gap={1} onClick={handleClick}>
        <Avatar sx={{ height: 32, width: 32, cursor: 'pointer' }} src={avatar_url}>
          {first_name?.charAt(0)}
        </Avatar>
        <Stack>
          <Stack direction="row" gap={1} alignItems="center" flexWrap="nowrap">
            <Typography whiteSpace="nowrap" textOverflow="ellipsis" variant='body2' noWrap overflow='hidden' fontWeight={500}
              flexShrink={1}
            >{first_name} {last_name}</Typography>
            <VerificationChip />
          </Stack>
          <Typography textTransform="capitalize" variant='caption' noWrap color={theme => theme.palette.text.appGrey}>
            Patient
            {/* {role} */}
          </Typography>
        </Stack>
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableScrollLock
        disableAutoFocusItem
        sx={WrapperStyle}
      >
        <ListItem disablePadding>
          <ListItemButton onClick={redirectToSettings} sx={buttonStyle}>
            <ListItemIcon sx={iconWidth}>
              <SettingsLogo blackColor />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="Account Settings" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={openFeedbackModal} sx={buttonStyle}>
            <ListItemIcon sx={iconWidth}>
              <SupportLogo />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="Contact Us" />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton onClick={handleLogout} sx={buttonStyle}>
            {isLogoutLoading ? <CircularProgress
              disableShrink
              sx={{ margin: '0px auto 8px' }}
              size={16}
              thickness={3}
              variant="indeterminate"
            />
              : <>
                <ListItemIcon sx={iconWidth}>
                  <LogoutIcon sx={{ color: '#D92D20' }} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'body2', color: '#D92D20' }} primary="Log Out" />
              </>}
          </ListItemButton>
        </ListItem>
      </Menu>
      {openConfirm ?
        <Feedback
          open={openConfirm}
          handleClose={closeConfirm}
        />
        : null
      }
    </>
  )
}

export default ProfileDropdown