import { authApi } from 'apis/auth.api';
import { onMessageListener } from 'init-fcm';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUnreadNotifications } from 'slices/user.slice';
import sound from 'assets/audios/bell-audio.mp3'

const useListenNotifications = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Listen for incoming messages continuously
    const unsubscribe = onMessageListener((payload) => {
      dispatch(setUnreadNotifications(payload));
      dispatch(authApi.util.invalidateTags(["NOTIFICATIONS", "DROPDOWN_NOTIFICATIONS"]));
      const audio = new Audio(sound)
      audio?.play()
    });
    return () => {
      unsubscribe?.()
    }

    // No need to return unsubscribe here, because we're using a continuous listener
  }, [dispatch]);
}

export default useListenNotifications;
