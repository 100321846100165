import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "store/baseQuery";
import { paymentsApi } from "./payments.api";
import { bookAppointment } from "./bookAppointment.api";

export const supplementStore = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "supplementStore",
  tagTypes: ["Products", "Cart", "Collections", "Supplement-Orders", "Orders"],
  endpoints: (build) => ({
    getProducts: build.query({
      query({ query, cursor, collection }) {
        return {
          url: `/api/supplement_store/products/?`,
          method: "GET",
          params: {
            first: 25,
            query,
            after: cursor,
            collection_id: collection
          }
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, response, { arg }) => {
        if (arg?.query) {
          if (arg?.cursor && arg?.collection === currentCache?.collectionId) {
            return {
              ...response,
              products: [...currentCache?.products ?? [], ...response?.products ?? []],
            }
          }
          return {
            ...response,
          };
        }
        else if (arg?.cursor && arg?.collection === currentCache?.collectionId) {
          return {
            ...response,
            products: [...currentCache?.products ?? [], ...response?.products ?? []],
          }
        } else {
          return response;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
      },
      transformResponse: (response, _, { collection }) => ({
        products: response?.data?.products?.edges ?? [],
        hasNextPage: response?.data?.products?.pageInfo?.hasNextPage ?? false,
        cursor: response?.data?.products?.edges?.at(-1)?.cursor ?? null,
        collectionId: collection ?? ''
      }),
    }),
    getProductById: build.query({
      query(id) {
        return {
          url: `/api/supplement_store/products/${id}/`,
          method: "GET"
        };
      },
      transformResponse: (response) => response?.data?.product ?? {},
    }),
    getCartById: build.query({
      query() {
        return {
          url: `/api/unified_cart/`,
          method: "GET",
        };
      },
      transformResponse: (response) => ({
        healthieInvoice: response?.healthie_invoice?.data?.requestedPayment || {},
        cart: response.shopify_cart?.data?.cart || {},
        total: response?.combined_total ?? 0,
        packageAmount: response?.healthie_total ?? 0,
      }),
      providesTags: ["Cart"],
      keepUnusedDataFor: 300,
    }),
    addToCart: build.mutation({
      query(body) {
        return {
          url: `/api/supplement_store/carts/add_to_cart/`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Cart"],
    }),
    removeFromCart: build.mutation({
      query(body) {
        return {
          url: `/api/supplement_store/carts/remove_from_cart/`,
          method: "POST",
          body,
        };
      },
      // onQueryStarted: async ({ line_id }, { queryFulfilled, dispatch }) => {
      //   const patchResult = dispatch(
      //     supplementStore.util.updateQueryData('getCartById', undefined, (draft) => {
      //       debugger;
      //     })
      //   )
      //   try {
      //     await queryFulfilled
      //   } catch {
      //     patchResult.undo()
      //   }
      // },
      invalidatesTags: ["Cart"],
    }),
    updateCart: build.mutation({
      query(body) {
        return {
          url: `/api/supplement_store/carts/update_cart_item/`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Cart"],
    }),
    getCollections: build.query({
      query() {
        return {
          url: `/api/supplement_store/collections/`,
          method: "GET"
        };
      },
      transformResponse: (response) => {
        const transformedData = response?.data?.collections?.edges?.map(({ node }) =>
          ({ label: node?.title, value: node?.id })
        ) ?? [];
        transformedData.unshift({ label: 'All', value: '' });
        return transformedData;
      },
      providesTags: ["Collections"],
      keepUnusedDataFor: 300,
    }),

    getOrders: build.query({
      query({ pageSize = '', cursor = '', startCursor = '', last = '' }) {
        return {
          url: `/api/supplement_store/orders/`,
          method: "GET",
          params: {
            first: pageSize,
            after: cursor,
            before: startCursor,
            last
          }
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, response) => {
        return {
          ...response,
          totalCount: currentCache?.totalCount,
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
      },
      transformResponse: (response) => ({
        products: response?.data?.customer?.orders?.edges ?? [],
        hasNextPage: response?.data?.customer?.orders?.pageInfo?.hasNextPage ?? false,
        cursor: response?.data?.customer?.orders?.pageInfo?.endCursor ?? null,
        startCursor: response?.data?.customer?.orders?.pageInfo?.startCursor ?? null,
        hasPreviousPage: response?.data?.customer?.orders?.pageInfo?.hasPreviousPage ?? false,
        totalCount: response?.data?.customer?.orders?.totalCount ?? 0,
      }),
      providesTags: ["Orders"],
    }),

    getSupplmentOrders: build.query({
      query({ pageSize = 25, cursor = '', startCursor = '' }) {
        return {
          url: `/api/supplement_store/orders/`,
          method: "GET",
          params: {
            first: pageSize,
            after: cursor,
            before: startCursor
          }
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, response) => {
        return {
          ...response,
          products: [...currentCache?.products ?? [], ...response?.products ?? []],
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.cursor !== previousArg?.cursor
      },
      transformResponse: (response) => ({
        products: response?.data?.customer?.orders?.edges ?? [],
        hasNextPage: response?.data?.customer?.orders?.pageInfo?.hasNextPage ?? false,
        cursor: response?.data?.customer?.orders?.pageInfo?.endCursor ?? null,
        startCursor: response?.data?.customer?.orders?.pageInfo?.startCursor ?? null,
        hasPreviousPage: response?.data?.customer?.orders?.pageInfo?.hasPreviousPage ?? false,
        totalCount: response?.data?.customer?.orders?.totalCount ?? 0,
      }),
      providesTags: ["Supplement-Orders"],
    }),
    reorderSupplments: build.mutation({
      query(id) {
        return {
          url: `/api/supplement_store/orders/re_order/`,
          method: "POST",
          body: {
            order_id: id
          }
        };
      },
      invalidatesTags: ["Cart"],
      keepUnusedDataFor: 0,
    }),

    getOrderById: build.query({
      query(id) {
        return {
          url: `/api/supplement_store/orders/?`,
          method: "GET",
          params: {
            order_id: id
          }
        };
      },
      transformResponse: (response) => ({
        ...response?.data?.node,
        error: response?.data?.node === null
      })
    }),

    createOrder: build.mutation({
      query() {
        return {
          url: `/api/supplement_store/orders/`,
          method: "POST",
        };
      },
      transformResponse: (response) => response?.data
    }),

    createNewCart: build.mutation({
      query(body) {
        return {
          url: `/api/supplement_store/carts/`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Cart"],
    }),

    getCheckoutDetail: build.query({
      query() {
        return {
          url: `/api/unified_cart/checkout/`,
          method: "GET",
        };
      },
      transformResponse: (response) => ({
        shopifyOrder: response?.draft_order?.data?.draftOrder ?? {},
        healthieInvoice: response?.healthie_invoice_data?.data?.requestedPayment ?? {},
        totalAmount: response?.combined_total ?? 0,
        packageAmount: response?.healthie_total ?? 0,
        orderTax: response?.tax ?? 0,
        labTax: response?.lab_tax ?? 0,
        consultationTax: response?.consultation_tax ?? 0,
        // orderTax: response?.tax?.amount_to_collect ?? 0,
      }),
      keepUnusedDataFor: 0,
    }),
    deleteSupplementOrder: build.mutation({
      query(id) {
        return {
          url: `api/supplement_store/orders/cancel_order/?`,
          method: "DELETE",
          params: {
            order_id: id
          }
        };
      },
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          dispatch(paymentsApi.util.invalidateTags('Invoices'));
          dispatch(bookAppointment.util.invalidateTags('Appointments'));
        } catch (err) {
          console.log("error... ", err);
        }
      },
      // invalidatesTags: ["Orders"],
      invalidatesTags: ["Orders"],
    }),
    removePackageFromCart: build.mutation({
      query() {
        return {
          url: `/api/unified_cart/remove_from_cart/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Cart"],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetProductByIdQuery,
  useGetCartByIdQuery,
  useAddToCartMutation,
  useRemoveFromCartMutation,
  useUpdateCartMutation,
  useGetCollectionsQuery,
  useGetOrdersQuery,
  useReorderSupplmentsMutation,
  useGetOrderByIdQuery,
  useGetSupplmentOrdersQuery,
  useCreateOrderMutation,
  useCreateNewCartMutation,
  useGetCheckoutDetailQuery,
  useDeleteSupplementOrderMutation,
  useRemovePackageFromCartMutation,
} = supplementStore;
