import { Button, Stack, Typography } from '@mui/material'
import { cancelledAppointmentSvg } from 'assets/svgIcons'
import ModalBox from 'components/ModalBox'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const CartPopup = ({ open, handleClose }) => {
  const navigate = useNavigate();
  const sideEffect = () => {
    setTimeout(handleClose, 3000);
  }

  const gotoCart = () => {
    handleClose();
    navigate("/cart");
  }


  useEffect(sideEffect, [handleClose])

  return (
    <ModalBox
      open={open}
      handleClose={handleClose}
      maxWidth='250px'
    // headerTitle="Item Added"
    >
      <Stack direction="row" justifyContent="center">
        {cancelledAppointmentSvg}
      </Stack>
      <Typography variant='body2' fontWeight={500} textAlign="center" gutterBottom mb={2}>
        Added to cart
      </Typography>
      <Button variant='export' size='small' onClick={gotoCart}>
        Go To Cart
      </Button>
    </ModalBox>
  )
}

export default CartPopup